import React, { useState } from "react";
import "../../index.css";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { cn } from "../../lib/utils";
import { Card, CardContent } from "../../components/ui/card";
import { useQuery } from "react-query";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { resetAuth } from "../../store/features/authSlice";
import { useDispatch } from "react-redux";

function SummaryPanel() {
    const dispatch = useDispatch()
	const [slide, setSlide] = useState(0);
	const navigate = useNavigate();
	const { auth } = useAuth();
	const axiosPrivate = useAxiosPrivate();
	const [events, setEvents] = useState({
		totalPacked: 0,
		totalReceived: 0,
		totalShiped: 0,
		totalItemCommissioned: 0,
		totalItemDecommissioned: 0,
		totalSSCC: 0,
		totalSgtin: 0,
		totalUnpacked: 0,
	});
	function handlSlide(dir) {
		if (dir === "left") setSlide((prev) => prev + 1);
		else setSlide((prev) => prev - 1);
	}

	useQuery(
		"summary",
		() => {
			return axiosPrivate.get(
				`summary/?userClients=${auth.user.userClients}`
			);
		},

		{
			onSuccess: (data) => {
				setEvents(data.data.data);
			},
			onError: (error) => {
				if (error.response?.status === 401) {
					dispatch(resetAuth());
					navigate("/");
				}
			},
			refetchOnMount: true,
			refetchOnWindowFocus: false,
		}
	);

	return (
		<div>
			<Card className="relative  overflow-x-hidden">
				{slide < 4 && (
					<span className=" absolute left-[5px] top-[50%] hover:scale-[1.15] w-[30px] h-[30px] flex justify-center items-center rounded-[50%] bg-primary text-white cursor-pointer z-10 text-[14px] active:sale-[0.98]">
						<ChevronLeft onClick={() => handlSlide("left")} />
					</span>
				)}
				{slide > 0 && (
					<span className=" absolute right-[5px] top-[50%] hover:scale-[1.15] w-[30px] h-[30px] flex justify-center items-center rounded-[50%] bg-primary text-white cursor-pointer z-10 text-[14px] active:sale-[0.98]">
						<ChevronRight onClick={() => handlSlide("right")} />
					</span>
				)}

				<CardContent
					style={{ transform: `translateX(${slide * -228}px)` }}
					className={cn(`bg-white h-[156px]  w-fit
    flex items-center scroll-smooth transistion-all ease-in-out duration-1000  py-[10px]  translate-all overflow-hidden`)}
				>
					<div className="w-[250px] h-[93%] flex-shrink-0 border-r-[1px] flex-grow-1 ">
						<div className="w-full p-[5px] text-center capitalize">
							Total SGTINs Encoded
						</div>
						<div className="flex justify-center gap-[20px] items-center py-[20px]">
							<img
								src="/programming.gif"
								className="w-[50px]"
								alt=""
							/>
							<p className="text-[20px] font-bold">
								{events.totalSgtin}
							</p>
						</div>
					</div>
					<div className="w-[250px] h-[93%] flex-shrink-0 border-r-[1px] flex-grow-1 ">
						<div className="w-full p-[5px] text-center capitalize">
							Total SSCC Encoded
						</div>
						<div className="flex justify-center gap-[20px] items-center py-[20px]">
							<img
								src="/programming.gif"
								className="w-[50px]"
								alt=""
							/>
							<p className="text-[20px] font-bold">
								{events.totalSSCC}
							</p>
						</div>
					</div>

					<div className="w-[250px] h-[93%] flex-shrink-0 border-r-[1px] flex-grow-1 ">
						<div className="w-full p-[5px] text-center capitalize">
							Total Item Commissioned
						</div>
						<div className="flex justify-center gap-[20px] items-center py-[20px]">
							<img
								src="/barcodeAn.gif"
								className="w-[50px]"
								alt=""
								srcset=""
							/>
							<p className="text-[20px] font-bold">
								{events.totalItemCommissioned}
							</p>
						</div>
					</div>
					{/*  */}
					<div className="w-[250px] h-[93%] flex-shrink-0 border-r-[1px] flex-grow-1 ">
						<div className="w-full p-[5px] text-center capitalize">
							Total Item Decommissioned
						</div>
						<div className="flex justify-center gap-[20px] items-center py-[20px]">
							<img
								src="/remove.gif"
								className="w-[50px]"
								alt=""
							/>
							<p className="text-[20px] font-bold">
								{events.totalItemDecommissioned}
							</p>
						</div>
					</div>
					{/*  */}
					<div className="w-[250px] h-[93%] flex-shrink-0 border-r-[1px] flex-grow-1 ">
						<div className="w-full p-[5px] text-center capitalize">
							Total Item Packed
						</div>
						<div className="flex justify-center gap-[20px] items-center py-[20px]">
							<img
								src="/boxing.gif"
								className="w-[50px]"
								alt=""
							/>
							<p className="text-[20px] font-bold">
								{events.totalPacked}
							</p>
						</div>
					</div>
					{/*  */}
					<div className="w-[250px] h-[93%] flex-shrink-0 border-r-[1px] flex-grow-1 ">
						<div className="w-full p-[5px] text-center capitalize">
							Total Item Shipped
						</div>
						<div className="flex justify-center gap-[20px] items-center py-[20px]">
							<img src="/truck.gif" className="w-[50px]" alt="" />
							<p className="text-[20px] font-bold">
								{events.totalShiped}
							</p>
						</div>
					</div>
					{/*  */}
					<div className="w-[250px] h-[93%] flex-shrink-0 border-r-[1px] flex-grow-1 ">
						<div className="w-full p-[5px] text-center capitalize">
							Total Item Received
						</div>
						<div className="flex justify-center gap-[20px] items-center py-[20px]">
							<img src="/door.gif" className="w-[50px]" alt="" />
							<p className="text-[20px] font-bold">
								{events.totalReceived}
							</p>
						</div>
					</div>
					{/*  */}
					<div className="w-[250px] h-[93%] flex-shrink-0 border-r-[1px] flex-grow-1 ">
						<div className="w-full p-[5px] text-center capitalize">
							Total Item Unpacked
						</div>
						<div className="flex justify-center gap-[20px] items-center py-[20px]">
							<img
								src="/unboxing.gif"
								className="w-[50px]"
								alt=""
							/>
							<p className="text-[20px] font-bold">
								{events.totalUnpacked}
							</p>
						</div>
					</div>
				</CardContent>
			</Card>
		</div>
	);
}

export default SummaryPanel;
