import { AreaChart, Landmark, Layers3, UsersRound } from "lucide-react";
import { roles } from "../roles";

export const navigationLinks = [
	{
		label: "Dashboard",
		linkUrl: "/pages",
		icon: AreaChart,
		roles: [roles.all_roles],
	},
	{
		label: "Clients",
		linkUrl: "/pages/client",
		icon: Landmark,
		roles: [roles.sproxil_admin],
	},
	{
		label: "Users",
		linkUrl: "/pages/user",
		icon: UsersRound,
		roles: [roles.sproxil_admin],
	},
	{
		label: "Serialization",
		linkUrl: "/pages/serialization",
		icon: Layers3,
		roles: [roles.encodingRole],
	},
];
