import { useDebugValue } from "react";
import { useSelector } from "react-redux";

const useAuth = () => {
  const { auth } = useSelector((state) => state.auth);
  useDebugValue(auth, (auth) => (auth?.email ? "Logged In" : "Logged Out"));
  return useSelector((state) => state.auth);
};

export default useAuth;
