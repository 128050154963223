import React, { useState } from "react";
import {
	Pagination,
	PaginationContent,
	PaginationEllipsis,
	PaginationItem,
	PaginationNext,
	PaginationPrevious,
} from "./ui/pagination";

const Paginator = ({ currentPage, disabled, pageCount, onPageChange }) => {
	const buttonDisplayLimit = 5;
	const [startPage, setStartPage] = useState(1);
	const endPage = Math.min(startPage + buttonDisplayLimit - 1, pageCount);

	const nextSet = () => {
		if (currentPage + 1 === buttonDisplayLimit + startPage) {
			setStartPage((prev) => prev + buttonDisplayLimit);
		}
	};

	const prevSet = () => {
		if (currentPage === startPage) {
			setStartPage((prev) => prev - buttonDisplayLimit);
		}
	};

	return (
		<Pagination>
			<PaginationContent>
				<PaginationItem>
					<PaginationPrevious
						className={`cursor-pointer ${
							currentPage <= 1 ? "disable-btn" : ""
						}    ${disabled && "disable-btn"}`}
						onClick={() => {
							prevSet();
							onPageChange(currentPage - 1);
						}}
					/>
				</PaginationItem>
				{[...Array(endPage - startPage + 1)].map((_, i) => {
					const pageNumber = startPage + i;

					return (
						<PaginationItem
							className={`py-1 px-3 rounded-md cursor-pointer hover:bg-primary hover:text-white active:bg-primary active:text-white disabled:!bg-yellow-300 ${
								pageNumber === currentPage
									? "bg-primary text-white"
									: ""
							} ${disabled ? "disable-btn" : ""}`}
							onClick={() => {
								if (!disabled) {
									onPageChange(pageNumber);
								}
							}}
							disabled={true}
						>
							{pageNumber}
						</PaginationItem>
					);
				})}
				{pageCount > 5 && (
					<PaginationItem>
						<PaginationEllipsis />
					</PaginationItem>
				)}
				<PaginationItem>
					<PaginationNext
						className={`cursor-pointer ${
							currentPage < pageCount ? "" : "disable-btn"
						} ${disabled && "disable-btn"}`}
						onClick={() => {
							onPageChange(currentPage + 1);
							nextSet();
						}}
					/>
				</PaginationItem>
			</PaginationContent>
		</Pagination>
	);
};

export default Paginator;
