import { NavLink, useResolvedPath } from "react-router-dom";
import { navigationLinks } from "../constants/navlinks";
import {
	Sidebar,
	SidebarContent,
	SidebarGroup,
	SidebarGroupContent,
	SidebarHeader,
	SidebarMenu,
	SidebarMenuButton,
	SidebarMenuItem,
} from "./ui/sidebar";
import { useVerifyUserRole } from "../verifyRoles";

const SidebarCollapsible = () => {
	const path = useResolvedPath();
	const verifyRoles = useVerifyUserRole();

	return (
		<Sidebar collapsible="icon">
			<SidebarHeader className="h-[52px]">
				<img
					src="/sproxillogo.png"
					alt="sproxil logo"
					className="w-[117px]"
				/>
			</SidebarHeader>
			<SidebarContent>
				<SidebarGroup className="mt-4">
					<SidebarGroupContent>
						<SidebarMenu>
							{navigationLinks.map((item) => (
								<SidebarMenuItem key={item.label}>
									<SidebarMenuButton
										asChild
										size={"lg"}
										tooltip={item.label}
										isActive={
											path.pathname === item.linkUrl
										}
									>
										<NavLink
											to={
												verifyRoles(...item.roles)
													? item.linkUrl
													: "#"
											}
											className={
												verifyRoles(...item.roles)
													? ""
													: "!text-muted-foreground"
											}
										>
											{" "}
											<item.icon size={28} />
											<span>{item.label}</span>
										</NavLink>
									</SidebarMenuButton>
								</SidebarMenuItem>
							))}
						</SidebarMenu>
					</SidebarGroupContent>
				</SidebarGroup>
			</SidebarContent>
		</Sidebar>
	);
};

export default SidebarCollapsible;
