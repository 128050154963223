import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "../index.css";
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useQuery } from "react-query";
import Footer from "../components/Footer";
import Notification from "../components/Notification";
import SidebarCollapsible from "../components/SidebarCollapsible";
import { SidebarTrigger, useSidebar } from "../components/ui/sidebar";
import UserQuickAction from "../components/UserAction";
import { setClients } from "../store/features/clientSlice";
import { useDispatch } from "react-redux";

function Home() {
    const dispatch = useDispatch()
	const { auth } = useAuth();
	const { state } = useSidebar();
	// const [errorResponse, setErrorResponse] = useState("");
	const axiosPrivate = useAxiosPrivate();
	const navigate = useNavigate();
	const location = useLocation();
	useQuery({
		queryKey: ["clients-locations"],
		queryFn: () =>
			axiosPrivate.get(
				`client/user-clients/?clients=${auth.user.userClients}`
			),
		refetchOnMount: true,
		refetchOnWindowFocus: false,
		onSuccess: (data) => {
			dispatch(setClients(data.data.data));
		},
		onError: (error) => {
			console.log(error);
			if (error?.response?.status === 401)
				navigate("/", { state: { from: location }, replace: true });
			else console.log(error);
		},
	});
	return (
		<div className="flex w-[100vw]">
			<section className="bg-[rgba(120,0,0,255)]">
				<SidebarCollapsible />
			</section>

			<section
				className={`${
					state === "collapsed"
						? "w-[calc(100dvw-4rem)]"
						: "w-[calc(100dvw-16rem)]"
				} transition-[width] ease-linear duration-500`}
			>
				<nav className="sticky top-0 right-0 h-[55px] w-full  z-20">
					<div
						id="container"
						className="flex justify-end w-full h-full"
					>
						<div className="w-[320px] flex items-center gap-[14px] bg-white h-full p-[10px]">
							{" "}
							<Notification />
							<UserQuickAction />
							<span>{auth?.user?.email}</span>
						</div>
					</div>
				</nav>
				<main
					className={`my-[30px] transition-[margin] ease-out duration-500 w-[96%] m-auto min-h-[70vh]`}
				>
					<SidebarTrigger className="mb-6" />
					<Outlet />
				</main>
				<Footer />
			</section>
		</div>
	);
}

export default Home;
