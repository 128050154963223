import { CircleUser, LogOut } from "lucide-react";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "../components/ui/popover";
import { Button } from "./ui/button";
import { useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useToast } from "./ui/use-toast";
import { ClipLoader } from "react-spinners";
import { useState } from "react";
import { resetAuth } from "../store/features/authSlice";
import { useDispatch } from "react-redux";
import { resetClients } from "../store/features/clientSlice";

const UserQuickAction = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const axiosprivate = useAxiosPrivate();
	const { toast } = useToast();
	const [isLoading, setIsLoading] = useState(false);

	const handleLogOut = async () => {
		setIsLoading(true);
		try {
			await axiosprivate.get("log-out");
			dispatch(resetAuth());
			dispatch(resetClients());
			setIsLoading(false);
			navigate("/", { state: { from: location }, replace: true });
		} catch (error) {
			setIsLoading(false);
			toast({
				variant: "destructive",
				title: "Error",
				description: `Some thing went wrong. Try again later`,
			});
		}
	};
	return (
		<Popover>
			<PopoverTrigger asChild>
				<CircleUser size={20} cursor={"pointer"} />
			</PopoverTrigger>
			<PopoverContent className="w-[100%] mt-1" align={"end"}>
				<Button
					onClick={handleLogOut}
					disabled={isLoading}
					variant="outline"
					className="border-0 shadow-none disabled:!cursor-default disabled:!bg-muted"
				>
					{isLoading ? (
						<span className="flex items-center gap-2 justify-between">
							<ClipLoader color="primary" size={20} />
							Logging out
						</span>
					) : (
						<span className="flex items-center gap-2 justify-between">
							<LogOut size={20} strokeWidth={1.5} />
							Log out
						</span>
					)}
				</Button>
			</PopoverContent>
		</Popover>
	);
};

export default UserQuickAction;
