import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";
import CommissionedTab from "./CommissionedTab";
import PackTab from "./PackTab";
import ShipTab from "./ShipTab";
function Tab() {
  return (
    <div className="my-[30px]">
      <Tabs defaultValue="commissioned" className="w-full overflow-hidden">
        <TabsList>
          <TabsTrigger value="commissioned">Items Commissioned</TabsTrigger>
          <TabsTrigger value="packed">Items Packed</TabsTrigger>
          <TabsTrigger value="shipped">Items Shipped</TabsTrigger>
        </TabsList>
        <TabsContent value="commissioned">
          <CommissionedTab />
        </TabsContent>
        <TabsContent value="packed">
          <PackTab />
        </TabsContent>
        <TabsContent value="shipped">
          <ShipTab />
        </TabsContent>
      </Tabs>
    </div>
  );
}

export default Tab;
