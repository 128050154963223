import { useDispatch } from "react-redux";
import { setAuth } from "../store/features/authSlice";
import axios from "../utils/apis/axios";

const useRefreshToken = () => {
const dispatch = useDispatch()

  const refresh = async () => {
    const response = await axios.get("/refresh", {
      withCredentials: true,
    });
    dispatch(setAuth({
      accessToken: response.data.accessToken,
      user: response.data.user,
      providerToken: response.data.providerToken,
      login: true,
    }));
    return response?.data?.accessToken;
  };
  return refresh;
};

export default useRefreshToken;
