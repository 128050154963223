import React, { useState } from "react";
import {
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
} from "../components/ui/card";
import {
	Table,
	TableBody,
	TableCaption,
	TableCell,
	TableFooter,
	TableHead,
	TableHeader,
	TableRow,
} from "../components/ui/table";
import { Button } from "../components/ui/button";
import { format, isValid, parseISO } from "date-fns";

import {
	Sheet,
	SheetContent,
	SheetDescription,
	SheetHeader,
	SheetTitle,
	SheetTrigger,
} from "../components/ui/sheet";

import { useQuery } from "react-query";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useLocation, useNavigate } from "react-router-dom";
import { Input } from "../components/ui/input";
import { useToast } from "../components/ui/use-toast";
import EditUser from "../components/EditUser";
import NewUser from "../components/NewUser";
import { ClipLoader } from "react-spinners";
import Paginator from "../components/Paginator";
import { useSelector } from "react-redux";

let pageCount = 0;
let total_row = 0;
function User() {
	const [open, setOpen] = useState(false);
	const axiosPrivate = useAxiosPrivate();
	const navigate = useNavigate();
	const [eventList, setEventList] = useState([]);
	const location = useLocation();
	const { toast } = useToast();
    const { allClients } = useSelector((state) => state.clients);
	const [currentPage, setCurrentPage] = useState(1);
	const [user, setUser] = useState({});
	const getAlUsersPaginated = async (page) => {
		const response = await axiosPrivate.get(`user?currentPage=${page}`);
		return response;
	};
	const { refetch, isFetching } = useQuery({
		queryKey: ["Allusers", currentPage],
		queryFn: () => getAlUsersPaginated(currentPage),
		onError: (error) => {
			if (error.code === "ERR_NETWORK") {
				navigate("/", { state: { from: location }, replace: true });
			} else if (
				error.response?.status === 401 ||
				error.response?.status === 403
			) {
				navigate("/", { state: { from: location }, replace: true });
			} else {
				toast({
					variant: "destructive",
					title: "Error",
					description: `${error?.response?.data?.message}`,
				});
			}
		},
		onSuccess: (data) => {
			pageCount = data?.data?.data?.pageCount;
			setEventList(data?.data?.data?.row);
			total_row = data?.data?.data?.total;
		},
		refetchOnMount: true,
		refetchOnWindowFocus: false,
	});

	function handleParams() {
		setCurrentPage(1);
		refetch();
	}

	const onPageChange = (page) => {
		setCurrentPage(page);
	};

	function handleDate(date) {
		if (date == null) return "N/A";
		const validDate = parseISO(date, "yyyy-MM-dd", new Date());
		if (isValid(validDate))
			return format(new Date(date), "yyyy-MM-dd hh:mm:ss");
		return "N/A";
	}

	const handleEdit = (e, user) => {
		e.preventDefault();
		setUser(user);
		setOpen(true);
	};
	return (
		<>
			<Sheet open={open} onOpenChange={setOpen}>
				<SheetContent className=" min-w-[650px]">
					<SheetHeader>
						<SheetTitle>Edit User Profile</SheetTitle>
						<SheetDescription>
							<EditUser user={user} />
						</SheetDescription>
					</SheetHeader>
				</SheetContent>
			</Sheet>
			<div className=" overflow-hidden">
				<Card>
					<CardHeader>
						<CardTitle className="my-[10px]">
							List of all users
						</CardTitle>
						<CardDescription className="flex gap-[10px] w-[60vw]">
							<Input
								className="w-[80%]"
								placeholder="Enter user email here"
							/>
							<Button onClick={handleParams}>Search</Button>
							<Sheet>
								<SheetTrigger asChild>
									<Button variant="outline">New User</Button>
								</SheetTrigger>
								<SheetContent className=" min-w-[450px]">
									<SheetHeader>
										<SheetTitle>
											New User Registration
										</SheetTitle>
										<SheetDescription>
											Enter the required user profile
											here. Click submit when you're done.
										</SheetDescription>
									</SheetHeader>
									<NewUser />
								</SheetContent>
							</Sheet>
						</CardDescription>
					</CardHeader>
					<CardContent className="space-y-2">
						<Table className="">
							<TableCaption>
								A list of All Available Users
							</TableCaption>
							<TableHeader>
								<TableRow>
									<TableHead>Client</TableHead>
									<TableHead className=" ">Name(s)</TableHead>
									<TableHead>Email</TableHead>
									<TableHead>Roles</TableHead>
									<TableHead>Date</TableHead>
									<TableHead className="text-right">
										Action
									</TableHead>
								</TableRow>
							</TableHeader>
							<TableBody>
								{isFetching && (
									<TableRow>
										<TableCell colSpan={7}>
											<div className="flex justify-center">
												<ClipLoader />
											</div>
										</TableCell>
									</TableRow>
								)}
								{
									!isFetching && eventList.map((event) => (
										<TableRow key={event.id}>
											<TableCell className="font-medium whitespace-nowrap ">
												{
													allClients.find(
														(client) =>
															client.key ===
															event?.client_key
													)?.name
												}
											</TableCell>
											<TableCell className="font-medium whitespace-nowrap">
												{event?.firstName}{" "}
												{event?.lastName}
											</TableCell>
											<TableCell>
												{event?.email}
											</TableCell>
											<TableCell className=" whitespace-break-spaces">
												{event?.role}
											</TableCell>
											<TableCell className="whitespace-nowrap">
												{handleDate(event?.createdAt)}
											</TableCell>
											<TableCell className="text-right">
												<Button
													variant="link"
													onClick={(e) =>
														handleEdit(e, event)
													}
												>
													Edit-user
												</Button>
											</TableCell>
										</TableRow>
									))
								}
							</TableBody>
							<TableFooter>
								<TableRow>
									<TableCell colSpan={5}>Total</TableCell>
									<TableCell colSpan={1}>
										{total_row}
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell colSpan={5}></TableCell>
									<TableCell>
										<div className=" flex">
											<div className=" justify-end">
												{pageCount > 1 && (
													<Paginator
														currentPage={
															currentPage
														}
														disabled={isFetching}
														onPageChange={
															onPageChange
														}
														pageCount={pageCount}
													/>
												)}
											</div>
										</div>
									</TableCell>
								</TableRow>
							</TableFooter>
						</Table>
					</CardContent>
					<CardFooter></CardFooter>
				</Card>
			</div>
		</>
	);
}

export default User;
