import React, { useState } from "react";
import {
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
} from "../../components/ui/card";
import {
	Table,
	TableBody,
	TableCaption,
	TableCell,
	TableFooter,
	TableHead,
	TableHeader,
	TableRow,
} from "../../components/ui/table";
import { Button } from "../../components/ui/button";

import { format, isValid, parseISO } from "date-fns";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { ClipLoader } from "react-spinners";
import Select from "react-select";
import Paginator from "../../components/Paginator";
import { useSelector } from "react-redux";
let pageCount = 0;
let total_row = 0;
function PackTab() {
	const [packSize, setPackSize] = useState([]);
	//
	//

	const axiosPrivate = useAxiosPrivate();
	// const { auth } = useAuth();
    const { allClients } = useSelector((state) => state.clients);
	const navigate = useNavigate();
	const [eventList, setEventList] = useState([]);
	const [prods, setProds] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);

	const getProductsbyKey = async (key) => {
		const response = await axiosPrivate.get(
			`client/products/${key.clientKey}`
		);
		return response;
	};

	const [params, setParams] = useState({
		startate: "2023-01-01",
		enddate: format(new Date(), "yyyy-MM-dd"),
		prodIds: "all",
		packSize: "all",
		clientKeys: "all",
	});

	const getAggregationData = async () => {
		const response = await axiosPrivate.get(
			`label/pack/table/?currentPage=${currentPage}
  &startdate=${params.startate}&enddate=${params.enddate}&packSize=${params.packSize}
  &clientKeys=${params.clientKeys}&prodIds=${params.prodIds}`
		);
		return response;
	};

	// const downloadEncodedData = async (data) => {
	// 	const response = await axiosPrivate.post(
	// 		"label/commission/download",
	// 		data
	// 	);
	// 	return response;
	// };

	// const header = [
	// 	{ key: "prodName", label: "product Name" },
	// 	{ key: "clientName", label: "Client Name" },
	// 	{ key: "labelType", label: "Label-Type" },
	// 	{ key: "count", label: "Volume" },
	// 	{ key: "createdAt", label: "Date" },
	// ];

	const { refetch, isFetching } = useQuery({
		queryKey: ["item-packed", currentPage],
		queryFn: () => getAggregationData(),
		refetchOnMount: true,
		refetchOnWindowFocus: false,
		onSuccess: (data) => {
			pageCount = data?.data?.data?.pageCount;
			setEventList(data?.data?.data?.row);
			total_row = data?.data?.data?.total;
		},
		onError: (error) => {
			console.log(error);
			if (
				error.response?.status === "403" ||
				error.response?.status === "401"
			) {
				navigate("/");
			}
			setEventList([]);
		},
	});

	const getPackSize = async (prodId) => {
		const response = await axiosPrivate.get(`label/pack/size/${prodId}`);
		return response;
	};
	const handlePackSize = async (id) => {
		try {
			const packSizeResp = await getPackSize(id);
			setPackSize(packSizeResp.data.data);
		} catch (error) {
			console.log(error);
		}
	};

	// const download = useMutation(
	// 	(variables) => downloadEncodedData(variables),
	// 	{
	// 		onSuccess(data, variables, context) {
	// 			setDownloadData(
	// 				data?.data?.data.map((event) => {
	// 					return {
	// 						prodName: event.prodName,
	// 						clientName: event.clientName,
	// 						labelType:
	// 							event.labelType === "0" ? "SGTIN" : "SSCC",
	// 						count: event.count,
	// 						createdAt: format(
	// 							new Date(event.createdAt),
	// 							"yyyy-MM-dd hh:mm:ss"
	// 						),
	// 					};
	// 				})
	// 			);
	// 		},
	// 		onError(error) {},
	// 	}
	// );

	const handleChange = async (key) => {
		try {
			const prodResponse = await getProductsbyKey({ clientKey: key });
			setProds([...prodResponse.data.data]);
		} catch (error) {
			console.log(error);
		}
	};

	function handleParams() {
		setCurrentPage(1);
		refetch();
	}

	const onPageChange = (page) => {
		setCurrentPage(page);
	};
	function handleDate(date) {
		const validDate = parseISO(date, "yyyy-MM-dd", new Date());
		if (isValid(validDate))
			return format(new Date(date), "yyyy-MM-dd hh:mm:ss");
		return "Invalid date-time";
	}

	return (
		<div className=" overflow-x-hidden">
			<Card className=" overflow-x-hidden">
				<CardHeader>
					<CardTitle className="my-[10px]">
						Items Commissioned
					</CardTitle>
					<CardDescription>
						<div className=" w-[100%] flex gap-10 mb-5">
							<Select
								className=" w-[50%] flex-1"
								// isMulti
								onChange={(e) => {
									handleChange(e.value);
									setParams({
										...params,
										clientName: e.value,
									});
								}}
								// placeholder="Select user roles"
								options={[
									{ value: "all", label: "all" },
									...allClients?.map((client) => {
										return {
											value: client?.key,
											label: client?.name,
										};
									}),
								]}
							/>
							<Select
								className=" w-[50%] flex-1"
								// isMulti
								onChange={(e) => {
									handlePackSize(e.value);
									setParams({ ...params, prodName: e.value });
								}}
								// placeholder="Select user roles"
								options={[
									...prods.map((prod, index) => {
										return {
											value: prod.id,
											label: prod.prod_name,
										};
									}),
								]}
							/>
						</div>

						<div className="w-[100%] flex align-middle gap-2 pt-3 pb-3">
							<Select
								className=" w-52"
								// isMulti
								onChange={(e) => {
									handleChange(e.value);
									setParams({ ...params, packSize: e.value });
								}}
								placeholder="Select Pack-size"
								options={[
									{ value: "all", label: "all" },
									...packSize?.map((size) => {
										return {
											value: size.count,
											label: size.count,
										};
									}),
								]}
							/>
							<span>
								<label htmlFor="" className=" p-3">
									Start-Date:
								</label>
								<input
									type="date"
									name="start-date"
									id=""
									className="w-60 border-2 cursor-pointer h-[42px]"
									onChange={(e) =>
										setParams({
											...params,
											startate: e.target.value,
										})
									}
								/>
							</span>

							<span className="">
								<label htmlFor="" className=" p-3">
									End-Date:
								</label>

								<input
									type="date"
									name="end-date"
									id=""
									onChange={(e) =>
										setParams({
											...params,
											enddate: e.target.value,
										})
									}
									className="w-60 border-2 cursor-pointer h-[42px]"
								/>
							</span>
							<Button onClick={handleParams}>Search</Button>
							{/* <Button onClick={handleLabelDownload}>Download</Button> */}
						</div>
					</CardDescription>
				</CardHeader>
				<CardContent className="space-y-2">
					<Table className=" overflow-x-hidden">
						<TableCaption>
							A list of all recent packing events.
						</TableCaption>
						<TableHeader>
							<TableRow>
								<TableHead className="whitespace-nowrap">
									Client Name
								</TableHead>
								<TableHead className="whitespace-nowrap">
									Product Name
								</TableHead>
								<TableHead className="whitespace-nowrap">
									Pack
								</TableHead>
								<TableHead className=" whitespace-nowrap">
									Pack Size
								</TableHead>
								<TableHead className="whitespace-nowrap">
									Date
								</TableHead>
							</TableRow>
						</TableHeader>
						<TableBody>
							{isFetching && (
								<TableRow>
									<TableCell colSpan={5}>
										<div className="flex justify-center">
											<ClipLoader />
										</div>
									</TableCell>
								</TableRow>
							)}
							{eventList?.map((event) => (
								<TableRow key={event?.id}>
									<TableCell className=" whitespace-nowrap">
										{event?.clientName}
									</TableCell>
									<TableCell className="whitespace-nowrap">
										{event?.prodName}
									</TableCell>
									<TableCell className=" whitespace-nowrap">
										{event?.parent}
									</TableCell>
									<TableCell className="whitespace-nowrap">
										{event?.count}
									</TableCell>
									<TableCell className="whitespace-nowrap">
										{handleDate(event?.createdAt)}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
						<TableFooter>
							<TableRow>
								<TableCell colSpan={4}>Total Rows</TableCell>
								<TableCell
									colSpan={1}
									className="flex justify-end"
								>
									{total_row}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell colSpan={4}></TableCell>
								<TableCell
									colSpan={1}
									className="flex justify-end"
								>
									{pageCount > 1 && (
										<Paginator
											currentPage={currentPage}
											disabled={isFetching}
											onPageChange={onPageChange}
											pageCount={pageCount}
										/>
									)}
								</TableCell>
							</TableRow>
						</TableFooter>
					</Table>
				</CardContent>
				<CardFooter></CardFooter>
			</Card>
		</div>
	);
}

export default PackTab;
