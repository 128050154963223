import React, { useState } from "react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "../components/ui/command";
import { Button } from "../components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../components/ui/popover";
import { Check, ChevronsUpDown, Trash2 } from "lucide-react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
import { cn } from "../lib/utils";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useMutation, useQuery } from "react-query";
import { clientType } from "../utils/roles";
import { useLocation, useNavigate } from "react-router-dom";
import { useToast } from "../components/ui/use-toast";
import { ClipLoader } from "react-spinners";
import { useSelector } from "react-redux";

function EditClient({ client }) {
  const [open, setOpen] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const [clientVal, setClientVal] = useState();
  const axiosPrivate = useAxiosPrivate();
const { allClients } = useSelector((state) => state.clients);
  const { toast } = useToast();
  const fetchPartnersByclientId = async (data) => {
    const response = await axiosPrivate.get(`/client/tradepartner/${data}`);
    return response;
  };
  const removePartner = async (data) => {
    const response = await axiosPrivate.delete(
      `/client/trade-partner/${data.clientId}/${data.partnerId}`
    );
    return response;
  };
  const addPartner = async (data) => {
    const response = await axiosPrivate.post(`/client/trade-partner`, data);
    return response;
  };
  const {
    data,
    isSuccess,
    isLoading: loading,
  } = useQuery(
    "tradePartners",
    () => {
      return fetchPartnersByclientId(client.id);
    },
    {
      onSuccess: (data) => {
        // console.log(data);
      },
      onError: (error) => {
        if (error.code === "ERR_NETWORK") {
          navigate("/", { state: { from: location }, replace: true });
        } else if (
          error.response.status === 401 ||
          error.response.status === 403
        ) {
          navigate("/", { state: { from: location }, replace: true });
        } else {
          toast({
            variant: "destructive",
            title: "Error",
            description: `${error?.response?.data?.message}`,
          });
        }
      },
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  const { mutate, isLoading } = useMutation(
    (variables) => removePartner(variables),
    {
      onSuccess(data, variables, context) {
        toast({
          variant: "success",
          title: "Success",
          description: `${"Partner successfully removed"}`,
        });
      },
      onError(error) {
        if (error.code === "ERR_NETWORK") {
          navigate("/", { state: { from: location }, replace: true });
        } else if (
          error.response.status === 401 ||
          error.response.status === 403
        ) {
          navigate("/", { state: { from: location }, replace: true });
        } else {
          toast({
            variant: "destructive",
            title: "Error",
            description: `${error?.response?.data?.message}`,
          });
        }
      },
    }
  );
  const { mutate: uploaddata, isLoading: uploadingData } = useMutation(
    (variables) => addPartner(variables),
    {
      onSuccess(data, variables, context) {
        toast({
          variant: "success",
          title: "success",
          description: `${"Partner added Successfully"}`,
        });
      },
      onError(error) {
        if (error.code === "ERR_NETWORK") {
          navigate("/", { state: { from: location }, replace: true });
        } else if (
          error.response.status === 401 ||
          error.response.status === 403
        ) {
          navigate("/", { state: { from: location }, replace: true });
        } else {
          toast({
            variant: "destructive",
            title: "Error",
            description: `${error?.response?.data?.message}`,
          });
        }
      },
    }
  );
  const handleRemovePartner = (e, id) => {
    e.preventDefault();
    mutate({ clientId: client.id, partnerId: id });
  };
  function handleSubmit(e) {
    e.preventDefault();
    const partnerId = allClients.find(
      (client) => client.key.toLowerCase() === clientVal
    ).id;
    console.log(partnerId);

    if (
      parseInt(client.id) !== parseInt(partnerId) &&
      parseInt(partnerId) !== 0
    ) {
      uploaddata({
        clientId: parseInt(client.id),
        partnerId: parseInt(partnerId),
      });
    }
  }
  return (
    <div>
      <div>
        <div>
          <div>Edit Trade Partner</div>
        </div>
        {client.clientType === 1 && (
          <fieldset style={{ padding: "20px", border: "1px solid #d6d6d6" }}>
            <div>
              <label>Add a new trade partner</label>
            </div>

            <Popover open={open} onOpenChange={setOpen}>
              <PopoverTrigger asChild>
                <Button
                  variant="outline"
                  role="combobox"
                  aria-expanded={open}
                  className="w-[200px] justify-between">
                  {clientVal
                    ? allClients.find(
                        (client) => (client?.key).toLowerCase() === clientVal
                      )?.name
                    : "Pick a client..."}
                  <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-[200px] p-0">
                <Command>
                  <CommandInput placeholder="Search client..." />
                  <CommandEmpty>No framework found.</CommandEmpty>
                  <CommandGroup>
                    {allClients.map((client, index) => (
                      <CommandItem
                        key={index}
                        value={client?.key}
                        onSelect={(currentValue) => {
                          setClientVal(currentValue);

                          setOpen(false);
                        }}>
                        <Check
                          className={cn(
                            "mr-2 h-4 w-4",
                            clientVal === client?.key
                              ? "opacity-100"
                              : "opacity-0"
                          )}
                        />
                        {client.name}
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </Command>
              </PopoverContent>
            </Popover>

            <div className="mt-[10px]">
              <Button width={"100%"} onClick={(e) => handleSubmit(e)}>
                {uploadingData ? (
                  <span className="mx-[20px]">
                    <ClipLoader color="primary" size={20} />
                  </span>
                ) : (
                  <span>Submit</span>
                )}
              </Button>
            </div>
          </fieldset>
        )}
        <div className=" relative">
          {loading ? (
            <div className=" p-[40px] flex justify-center items-center absolute z-50">
              <ClipLoader color="primary" size={35} />
            </div>
          ) : (
            <>
              <Table>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Partner Name</TableHead>
                      <TableHead>Client Category </TableHead>
                      <TableHead>Action</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {isSuccess && data?.data?.data?.length > 0 ? (
                      data?.data?.data.map((partner) => {
                        return (
                          <TableRow key={partner.id}>
                            <TableCell>{partner.name}</TableCell>
                            <TableCell>
                              {clientType[partner.clientType]}
                            </TableCell>
                            <TableCell>
                              {isLoading ? (
                                <ClipLoader color="primary" size={15} />
                              ) : (
                                <Trash2
                                  className=" text-primary cursor-pointer hover:text-secondary"
                                  active={
                                    client.clientType === 1 ? true : false
                                  }
                                  onClick={(e) =>
                                    handleRemovePartner(e, partner.id)
                                  }
                                />
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow colSpan="3">
                        <TableCell colSpan={3}>
                          <h1>NO DATA</h1>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Table>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default EditClient;
