import React, { useState } from "react";
import {
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
} from "../components/ui/card";
import {
	Table,
	TableBody,
	TableCaption,
	TableCell,
	TableFooter,
	TableHead,
	TableHeader,
	TableRow,
} from "../components/ui/table";
import { Button } from "../components/ui/button";
import { format, isValid, parseISO } from "date-fns";

import {
	Sheet,
	SheetContent,
	SheetDescription,
	SheetHeader,
	SheetTitle,
	SheetTrigger,
} from "../components/ui/sheet";

import { useQuery } from "react-query";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from "../hooks/useAuth";
import { useLocation, useNavigate } from "react-router-dom";
import { Input } from "../components/ui/input";
import EditClient from "./EditClient";
import NewClient from "../components/NewClient";
import { useToast } from "../components/ui/use-toast";
import { ClipLoader } from "react-spinners";
import Paginator from "../components/Paginator";

function Client() {
	const [open, setOpen] = useState(false);
	const [client, setClient] = useState();
	const axiosPrivate = useAxiosPrivate();
	const { auth } = useAuth();
	const navigate = useNavigate();
	const [eventList, setEventList] = useState([]);
	const location = useLocation();
	const { toast } = useToast();
	const [currentPage, setCurrentPage] = useState(1);
	const [pageCount, setPageCount] = useState(0);

	const { isLoading } = useQuery({
		queryKey: ["clients", currentPage],
		queryFn: () => getAllClientsPaginated(currentPage),
		onSuccess: (data) => {
			setEventList(data?.data?.data?.row);
			setPageCount(data?.data?.data?.pageCount);
		},
		onError: (error) => {
			if (error.code === "ERR_NETWORK") {
				navigate("/", { state: { from: location }, replace: true });
			} else if (
				error.response?.status === 401 ||
				error.response?.status === 403
			) {
				navigate("/", { state: { from: location }, replace: true });
			} else {
				toast({
					variant: "destructive",
					title: "Error",
					description: `${error?.response?.data?.message}`,
				});
			}
		},
		refetchOnMount: true,
		refetchOnWindowFocus: false,
	});

	const onPageChange = (page) => {
		setCurrentPage(page);
	};

	const getAllClientsPaginated = async (page) => {
		return await axiosPrivate.get(
			`client/table/?currentPage=${page}&clients=${auth.user.userClients}`
		);
	};

	//   const header = [
	//     { key: "prodName", label: "product Name" },
	//     { key: "clientName", label: "Client Name" },
	//     { key: "labelType", label: "Label-Type" },
	//     { key: "count", label: "Volume" },
	//     { key: "createdAt", label: "Date" },
	//   ];

	//   function handleParams() {
	//     const dateEnd =
	//       enddate !== undefined
	//         ? format(enddate, "yyyy-MM-dd hh:mm:ss")
	//         : format(new Date(), "yyyy-MM-dd hh:mm:ss");
	//     const dateStart =
	//       startdate !== undefined
	//         ? format(startdate, "yyyy-MM-dd hh:mm:ss")
	//         : new Date("2022-01-01");
	//     const clients = allClients.map((client) => client.key);
	//     const prods = auth.user?.userProducts.map((prod) => prod.id);
	//     const labels = labelType.map((label) => label.value);
	//     const filterArry = filter(staticDB, (x) => {
	//       return (
	//         (valueClient === ""
	//           ? clients.includes(x.clientKey)
	//           : valueClient === x.clientKey.toLowerCase()) &&
	//         (prodValue === ""
	//           ? prods.includes(x.prodId)
	//           : prodValue === x.prodName) &&
	//         (labelValue === "" || labelValue === "all"
	//           ? labels.includes(x.labelType)
	//           : parseInt(labelValue) === x.labelType) &&
	//         new Date(x.createdAt).getTime() >= new Date(dateStart).getTime() &&
	//         new Date(x.createdAt).getTime() <= new Date(dateEnd).getTime()
	//       );
	//     });
	//     setPageNumbers(() => {
	//       let count = [];
	//       for (
	//         let index = 0;
	//         index < Math.ceil(filterArry.length / pageLimit);
	//         index++
	//       ) {
	//         count.push(index + 1);
	//       }
	//       return count;
	//     });
	//     console.log(filterArry);
	//     setEventList(filterArry);
	//   }

	function handleDate(date) {
		if (date == null) return "N/A";
		const validDate = parseISO(date, "yyyy-MM-dd", new Date());
		if (isValid(validDate))
			return format(new Date(date), "yyyy-MM-dd hh:mm:ss");
		return "N/A";
	}

	const handleEdit = (e, event) => {
		e.preventDefault();
		setClient(event);
		setOpen(true);
	};
	return (
		<>
			<Sheet open={open} onOpenChange={setOpen}>
				<SheetContent>
					<SheetHeader>
						<SheetTitle>Edit Client Profile</SheetTitle>
						<SheetDescription>
							<EditClient client={client} />
						</SheetDescription>
					</SheetHeader>
				</SheetContent>
			</Sheet>
			<div className=" overflow-hidden">
				<Card>
					<CardHeader>
						<CardTitle className="my-[10px]">
							List of all clients
						</CardTitle>
						<CardDescription className="flex gap-[10px] w-[60vw]">
							<Input className="w-[80%]" />
							<Sheet>
								<SheetTrigger asChild>
									<Button variant="outline">
										New Client
									</Button>
								</SheetTrigger>
								<SheetContent>
									<SheetHeader>
										<SheetTitle>
											New Client Registration
										</SheetTitle>
										<SheetDescription>
											Enter the required client profile
											here. Click submit when you're done.
										</SheetDescription>
									</SheetHeader>
									<NewClient />
								</SheetContent>
							</Sheet>
						</CardDescription>
					</CardHeader>
					<CardContent className="space-y-2">
						<Table className="">
							<TableCaption>
								A list of All Available clients
							</TableCaption>
							<TableHeader>
								<TableRow>
									<TableHead className=" ">
										Client Name
									</TableHead>
									<TableHead>Date</TableHead>
									<TableHead>IS_NAFDAC MANDATE</TableHead>

									<TableHead className="text-right ">
										Trade Partner
									</TableHead>
								</TableRow>
							</TableHeader>
							<TableBody>
								{isLoading && (
									<TableRow>
										<TableCell colSpan={7}>
											<div className="flex justify-center">
												<ClipLoader />
											</div>
										</TableCell>
									</TableRow>
								)}
								{
									!isLoading && eventList.map((event) => (
										<TableRow key={event.id}>
											<TableCell className="font-medium  ">
												{event?.name}
											</TableCell>
											<TableCell>
												{handleDate(event?.createdAt)}
											</TableCell>
											<TableCell className=" text-center">
												{event.isNafdac === 1
													? "YES"
													: "NO"}
											</TableCell>
											<TableCell className="text-right">
												<Button
													variant="link"
													onClick={(e) =>
														handleEdit(e, event)
													}
												>
													view
												</Button>
											</TableCell>
										</TableRow>
									))
								}
							</TableBody>
							<TableFooter>
								<TableRow>
									<TableCell colSpan={4}>Total</TableCell>
								</TableRow>
								<TableRow>
									<TableCell colSpan={4}>
										{pageCount > 1 && (
											<Paginator
												currentPage={currentPage}
												disabled={isLoading}
												onPageChange={onPageChange}
												pageCount={pageCount}
											/>
										)}
									</TableCell>
								</TableRow>
							</TableFooter>
						</Table>
					</CardContent>
					<CardFooter></CardFooter>
				</Card>
			</div>
		</>
	);
}

export default Client;
