import useAuth from "./hooks/useAuth";

export const useVerifyUserRole = () => {
	const { auth } = useAuth();

	const userRoles = auth?.user?.role?.split(",") || [];
	function verifyRole(...userRole) {
		const verifyRoles = [...userRole];
		// eslint-disable-next-line array-callback-return
		const truthTable = userRoles.map((role) => {
			if (verifyRoles.includes(role.trim())) {
				return true;
			}
		});
		if (!truthTable.includes(true)) return false;
		else return true;
	}
	return verifyRole;
};
