import React, { useEffect, useState } from "react";
import {
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
} from "../../components/ui/card";
import {
	Table,
	TableBody,
	TableCaption,
	TableCell,
	TableFooter,
	TableHead,
	TableHeader,
	TableRow,
} from "../../components/ui/table";
import { Button } from "../../components/ui/button";
import { cn } from "../../lib/utils";
import { Calendar } from "../../components/ui/calendar";
import { CalendarIcon, ChevronsUpDown, Check, Download } from "lucide-react";
import { format, isValid, parseISO } from "date-fns";
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
} from "../../components/ui/command";
import { CSVDownload } from "react-csv";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "../../components/ui/popover";

import {
	Sheet,
	SheetContent,
	SheetDescription,
	SheetHeader,
	SheetTitle,
	SheetTrigger,
} from "../../components/ui/sheet";
import { useMutation, useQuery } from "react-query";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useLocation, useNavigate } from "react-router-dom";
import { filter } from "lodash";
import { useToast } from "../../components/ui/use-toast";
import { ClipLoader } from "react-spinners";
import SsccModal from "../../components/SsccModal";
import Paginator from "../../components/Paginator";
import { useSelector } from "react-redux";

let staticDB = [];
function Sscc() {
	const [startdate, setSatrtdate] = useState();
	const [enddate, setEndddate] = useState();
	const [openclient, setOpenClient] = useState(false);
	const [valueClient, setClientValue] = useState("");
	const [traceId, setTraceId] = useState("");
	const [dataDownload, setDataDownload] = useState([]);
	const [elemTarget, setElemTarget] = useState(null);
	const [pageCount, setPageCount] = useState(0);
	const [openProd, setOpenProd] = useState(false);
	const [prodValue, setProdValue] = useState("");

	//

	const axiosPrivate = useAxiosPrivate();
    const { allClients } = useSelector((state) => state.clients);
	const navigate = useNavigate();
	const [eventList, setEventList] = useState([]);
	const [prods, setProds] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const { toast } = useToast();
	const getProductsbyKey = async (key) => {
		const response = await axiosPrivate.get(
			`client/products/${key.clientKey}`
		);
		return response;
	};

	const handleChange = async (key) => {
		try {
			const prodResponse = await getProductsbyKey({ clientKey: key });
			setProds([...prodResponse.data.data]);
		} catch (error) {
			console.log(error);
		}
	};
	const header = [{ field: "elementString", key: "elementString" }];
	const csvReport = {
		fileName: `encoded-sscc-${traceId}`,
		header: header,
		data: dataDownload,
	};

	const getSSCCByTraceId = async (traceId) => {
		const response = await axiosPrivate.get(`label/sscc/${traceId}`);
		return response;
	};
	const handleDownload = () => {
		if (traceId !== "") {
			getSSCC.mutate(traceId);
		}
	};
	const getSSCC = useMutation((variables) => getSSCCByTraceId(variables), {
		onSuccess(data, variables, context) {
			setTraceId("");
			elemTarget.target.checked = false;
			setElemTarget(null);
			setDataDownload([...data.data.data]);
		},

		onError(error) {
			setTraceId("");
			elemTarget.target.checked = false;
			setElemTarget(null);
			if (error.code === "ERR_NETWORK") {
				console.log(error);
			} else if (
				error.response?.status === 403 ||
				error.response?.status === 401
			) {
				navigate("/", { state: { from: location }, replace: true });
			} else {
				toast({
					variant: "destructive",
					title: "Error",
					description: `${error?.response?.data?.message}`,
				});
			}
		},
	});

	function handleParams() {
		const dateEnd =
			enddate !== undefined
				? format(enddate, "yyyy-MM-dd hh:mm:ss")
				: format(new Date(), "yyyy-MM-dd hh:mm:ss");
		const dateStart =
			startdate !== undefined
				? format(startdate, "yyyy-MM-dd hh:mm:ss")
				: new Date("2022-01-01");
		const clients = allClients?.map((client) => client.name);
		const prods = staticDB?.map((prod) => prod.prodName);
		const filterArry = filter(staticDB, (x) => {
			return (
				(valueClient === ""
					? clients.includes(x.clientName)
					: valueClient === x.clientName.toLowerCase()) &&
				(prodValue === ""
					? prods.includes(x?.prodName)
					: prodValue === x.prodName) &&
				new Date(x.createdAt).getTime() >=
					new Date(dateStart).getTime() &&
				new Date(x.createdAt).getTime() <= new Date(dateEnd).getTime()
			);
		});
		setEventList(filterArry);
	}

	const onPageChange = (page) => {
		setCurrentPage(page);
	};

	const getAllSSCCPaginated = async (page) => {
		return await axiosPrivate.get(
			`label/sscc/table/?currentPage=${page}&clientKeys=all&prodIds=all`
		);
	};
	const [longitude, setLongitude] = useState();
	const [latitude, setLatitude] = useState();
	const location = useLocation();

	useEffect(() => {
		navigator.permissions.query({ name: "geolocation" }).then((resp) => {
			console.log("geting location ......................");
			console.log(resp);
			if (resp.state === "granted" || resp.state === "prompt") {
				console.log("granted");
				if (navigator.geolocation) {
					navigator.geolocation.getCurrentPosition(success, error);
				}
			} else if (resp.state === "denied") {
				navigate("/", { state: { from: location, replace: true } });
			}
		});

		function success(position) {
			setLatitude(`${position.coords.latitude}`);
			setLongitude(`${position.coords.longitude}`);
			console.log(position.coords.latitude);
		}

		function error(err) {
			console.log(err);
			navigate("/", { state: { from: location, replace: true } });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const { isLoading } = useQuery({
		queryKey: ["sscc", currentPage],
		queryFn: () => getAllSSCCPaginated(currentPage),
		refetchOnMount: true,
		refetchOnWindowFocus: false,
		onSuccess: (data) => {
			console.log(data);
			setEventList(data?.data?.data?.row);
			staticDB = data?.data?.data?.row;
			setPageCount(data?.data?.data?.pageCount);
		},
		onError: (error) => {
			if (error.code === "ERR_NETWORK") {
				navigate("/", { state: { from: location }, replace: true });
			} else if (
				error.response?.status === 401 ||
				error.response?.status === 403
			) {
				navigate("/", { state: { from: location }, replace: true });
			} else {
				toast({
					variant: "destructive",
					title: "Error",
					description: `${error?.response?.data?.message}`,
				});
			}
			setEventList([]);
		},
	});
	function handleDate(date) {
		if (date == null) return "N/A";
		const validDate = parseISO(date, "yyyy-MM-dd", new Date());
		if (isValid(validDate))
			return format(new Date(date), "yyyy-MM-dd hh:mm:ss");
		return "N/A";
	}

	return (
		<div className=" overflow-x-hidden">
			{getSSCC.isSuccess && <CSVDownload {...csvReport} target="_self" />}
			<Card className=" overflow-x-hidden">
				<CardHeader>
					<CardTitle className="my-[10px] flex justify-between items-center p-[20px]">
						{" "}
						<span className=" flex gap-[20px] items-center">
							<span>SSCC Generation Tab</span>
							<span>
								{" "}
								<Sheet>
									<SheetTrigger asChild>
										<Button variant="outline">
											Generate Label
										</Button>
									</SheetTrigger>
									<SheetContent className=" min-w-[550px]">
										<SheetHeader>
											<SheetTitle>
												Generate Label
											</SheetTitle>
											<SheetDescription>
												Enter the required SSCC details
												here. Click submit when you're
												done.
											</SheetDescription>
										</SheetHeader>
										<SsccModal
											longitude={longitude}
											latitude={latitude}
										/>
									</SheetContent>
								</Sheet>
							</span>
						</span>
						<span>
							<Button
								onClick={handleDownload}
								disabled={getSSCC.isLoading}
							>
								{getSSCC.isLoading ? (
									<ClipLoader color="primary" size={20} />
								) : (
									<Download />
								)}
							</Button>
						</span>
					</CardTitle>
					<CardDescription className="flex gap-[10px]">
						<Popover open={openclient} onOpenChange={setOpenClient}>
							<PopoverTrigger asChild>
								<Button
									variant="outline"
									role="combobox"
									aria-expanded={openclient}
									className="w-[200px] justify-between"
								>
									{valueClient
										? valueClient
										: "Pick a client..."}
									<ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
								</Button>
							</PopoverTrigger>
							<PopoverContent className="w-[200px] p-0">
								<Command>
									<CommandInput placeholder="Search client..." />
									<CommandEmpty>
										No Client found.
									</CommandEmpty>
									<CommandGroup>
										{allClients?.map((client) => (
											<CommandItem
												key={client.id}
												value={client?.name}
												onSelect={(currentValue) => {
													setClientValue(
														currentValue
													);
													handleChange(client.key);
													setOpenClient(false);
												}}
											>
												<Check
													className={cn(
														"mr-2 h-4 w-4",
														valueClient ===
															client?.name
															? "opacity-100"
															: "opacity-0"
													)}
												/>
												{client.name}
											</CommandItem>
										))}
									</CommandGroup>
								</Command>
							</PopoverContent>
						</Popover>

						<Popover open={openProd} onOpenChange={setOpenProd}>
							<PopoverTrigger asChild>
								<Button
									variant="outline"
									role="combobox"
									aria-expanded={openProd}
									className="w-[200px] justify-between"
								>
									{prodValue
										? prods.find(
												(prod) =>
													prod.product_name ===
													prodValue
										  )?.product_name
										: "Pick a product..."}
									<ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
								</Button>
							</PopoverTrigger>
							<PopoverContent className="w-[200px] p-0">
								<Command>
									<CommandInput placeholder="Search product..." />
									<CommandEmpty>
										No product found.
									</CommandEmpty>
									<CommandGroup>
										{prods?.map((prod, index) => (
											<CommandItem
												key={index}
												value={prod.product_name}
												onSelect={(currentValue) => {
													setProdValue(currentValue);
													setOpenProd(false);
												}}
											>
												<Check
													className={cn(
														"mr-2 h-4 w-4",
														prodValue ===
															prod.product_name
															? "opacity-100"
															: "opacity-0"
													)}
												/>
												{prod.product_name}
											</CommandItem>
										))}
									</CommandGroup>
								</Command>
							</PopoverContent>
						</Popover>
						<Popover>
							<PopoverTrigger asChild>
								<Button
									variant={"outline"}
									className={cn(
										"w-[280px] justify-start text-left font-normal",
										!startdate && "text-muted-foreground"
									)}
								>
									<CalendarIcon className="mr-2 h-4 w-4" />
									{startdate ? (
										format(startdate, "PPP")
									) : (
										<span>Start date</span>
									)}
								</Button>
							</PopoverTrigger>
							<PopoverContent className="w-auto p-0">
								<Calendar
									mode="single"
									selected={startdate}
									onSelect={setSatrtdate}
									initialFocus
								/>
							</PopoverContent>
						</Popover>

						<Popover>
							<PopoverTrigger asChild>
								<Button
									variant={"outline"}
									className={cn(
										"w-[280px] justify-start text-left font-normal",
										!enddate && "text-muted-foreground"
									)}
								>
									<CalendarIcon className="mr-2 h-4 w-4" />
									{enddate ? (
										format(enddate, "PPP")
									) : (
										<span>End date</span>
									)}
								</Button>
							</PopoverTrigger>
							<PopoverContent className="w-auto p-0">
								<Calendar
									mode="single"
									selected={enddate}
									onSelect={setEndddate}
									initialFocus
								/>
							</PopoverContent>
						</Popover>
						<Button onClick={handleParams}>Search</Button>
					</CardDescription>
				</CardHeader>
				<CardContent className="space-y-2">
					<Table className=" overflow-x-hidden">
						<TableCaption>
							A list of all generated sscc labels.
						</TableCaption>
						<TableHeader>
							<TableRow>
								<TableHead></TableHead>
								<TableHead>Product Name</TableHead>
								<TableHead>Client Name</TableHead>

								<TableHead>Volume</TableHead>
								<TableHead>Tag</TableHead>
								<TableHead className="text-right">
									Date-Generated
								</TableHead>
							</TableRow>
						</TableHeader>
						<TableBody>
							{isLoading && (
								<TableRow>
									<TableCell colSpan={7}>
										<div className="flex justify-center">
											<ClipLoader />
										</div>
									</TableCell>
								</TableRow>
							)}
							{
								!isLoading && eventList?.map((event) => (
									<TableRow key={event.id}>
										<TableCell>
											<input
												style={{ cursor: "pointer" }}
												type="radio"
												name="sgtinTrace"
												onChange={(e) => {
													setTraceId(event.traceId);
													setElemTarget(e);
												}}
											/>
										</TableCell>
										<TableCell className=" whitespace-nowrap">
											{event?.clientName}
										</TableCell>
										<TableCell className=" whitespace-nowrap">
											{event?.prodName}
										</TableCell>

										<TableCell>{event?.count}</TableCell>
										<TableCell className=" whitespace-nowrap">
											{event?.tag}
										</TableCell>
										<TableCell className="text-right">
											{handleDate(event?.createdAt)}
										</TableCell>
									</TableRow>
								))
							}
						</TableBody>
						<TableFooter>
							<TableRow>
								<TableCell colSpan={5}>Total</TableCell>
								<TableCell className="text-right"></TableCell>
							</TableRow>
							<TableRow>
								<TableCell colSpan={5}></TableCell>
								<TableCell>
									{pageCount > 1 && (
										<Paginator
											currentPage={currentPage}
											disabled={isLoading}
											onPageChange={onPageChange}
											pageCount={pageCount}
										/>
									)}
								</TableCell>
							</TableRow>
						</TableFooter>
					</Table>
				</CardContent>
				<CardFooter></CardFooter>
			</Card>
		</div>
	);
}

export default Sscc;
