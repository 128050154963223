import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	auth: null,
};

export const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		setAuth: (state, {payload}) => {
			return { ...state, auth: payload };
		},
		resetAuth: (state) => {
			return { ...state, auth: null };
		}
	},
});

export const { setAuth, resetAuth } = authSlice.actions;

export default authSlice.reducer;
