import React from "react";
import { Bell, CalendarDays } from "lucide-react";
import { PuffLoader } from "react-spinners";
import { useToast } from "./ui/use-toast";

import {
	Sheet,
	SheetDescription,
	SheetTitle,
	SheetTrigger,
	SheetContent,
	SheetHeader,
} from "./ui/sheet";

import {
	useClearNotifications,
	useGetUserNotifications,
} from "../hooks/notification";
import useAuth from "../hooks/useAuth";
import { formatDate } from "../utils/helpers";

const Notification = () => {
	const { toast } = useToast();
	const { auth } = useAuth();
	const { refetch, data, isLoading, isError } = useGetUserNotifications(
		auth?.user?.id || ""
	);

	const clearNotifications = useClearNotifications({
		onSuccess: () => {
			toast({
				variant: "success",
				title: "Success",
				description: `Notifications Cleared Succcessfully`,
			});
		},
		onError: (error) => {
			if (error.code !== "ERR_NETWORK") {
				toast({
					variant: "destructive",
					title: "Error",
					description: `${error?.response?.data?.message}`,
				});
			} else {
				toast({
					variant: "destructive",
					title: "Error",
					description: "Please Check Your Network",
				});
			}
		},
	});

	const notificationCount = data.length;

	return (
		<Sheet onOpenChange={(open) => {
            if (open) {refetch()}
        }}>
			<SheetTrigger asChild>
				<span className="relative flex w-[30px] items-center gap-1 -mb-[1px]">
					<Bell size={18} className="relative cursor-pointer" />
					{notificationCount > 0 && (
						<span
							className={`absolute -top-[12px] ${
								notificationCount > 99
									? "-right-2"
									: "right-[2px]"
							} m-0 bg-destructive rounded-full min-w-[20px] w-fit px-1 h-[18px] text-[12px] text-center flex items-center justify-center text-white`}
						>
							{notificationCount > 99 ? "99+" : notificationCount}
						</span>
					)}
				</span>
			</SheetTrigger>
			<SheetContent className="bg-muted">
				<SheetHeader>
					<SheetTitle className="font-semibold flex items-center justify-between mt-5 !outline-0">
						Notifications{" "}
						{data?.length > 0 && (
							<button
								type="button"
								className="font-light text-destructive hover:bg-red-200 rounded-sm px-1"
								onClick={() => {
									clearNotifications.clear(
										auth?.user?.id || ""
									);
								}}
								disabled={data?.length === 0}
							>
								clear all
							</button>
						)}
					</SheetTitle>
				</SheetHeader>
				<SheetDescription className="mt-5">
					{!isLoading && data.length > 0 && (
						<div className="flex flex-col gap-4 h-[86dvh] overflow-y-scroll thin-y-scroll-bar">
							{data.map((notification) => (
								<div key={notification.id} className="mr-2">
									<NotificationCard {...notification} />
								</div>
							))}
						</div>
					)}

					{!isLoading && data.length === 0 && (
						<div className="min-h-[80dvh] flex items-center justify-center text-[16px] text-center leading-7">
							No notification yet, <br />
							new notifications will be displayed here.
						</div>
					)}

					{isError && (
						<div className="min-h-[80dvh] flex items-center justify-center text-destructive text-[16px] text-center leading-7">
							Unable to load notifications, <br /> try again in
							some minutes
						</div>
					)}

					{isLoading && (
						<div className="min-h-[80dvh] flex flex-col gap-5 items-center justify-center text-[16px]">
							<PuffLoader color="#7f1d1d" size={40} />
							Loading notifications
						</div>
					)}
				</SheetDescription>
			</SheetContent>
		</Sheet>
	);
};

export default Notification;

const NotificationCard = ({ created_at, message }) => {
	return (
		<div className="bg-white p-3 rounded-lg">
			<p className="mb-4">{message}</p>
			<span className="mb-1 flex items-center gap-1 text-[13px] text-[#aaaaaa]">
				<CalendarDays size={14} color="#aaaaaa" />
				{formatDate(created_at)}
			</span>
		</div>
	);
};
