import React, { useState } from "react";
import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from "../../components/ui/card";
import {
	Table,
	TableBody,
	TableCaption,
	TableCell,
	TableFooter,
	TableHead,
	TableHeader,
	TableRow,
} from "../../components/ui/table";
import { Button } from "../../components/ui/button";

import { format } from "date-fns";
import { useQuery } from "react-query";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { ClipLoader } from "react-spinners";
import Paginator from "../../components/Paginator";
import { useSelector } from "react-redux";

const labelType = [
	{ value: "all", label: "ALL" },
	{ value: "0", label: "SGTIN" },
	{ value: "1", label: "SSCC" },
];
let pageCount = 0;
function CommissionedTab() {
	const axiosPrivate = useAxiosPrivate();
    const { allClients } = useSelector((state) => state.clients);
	const navigate = useNavigate();
	const [eventList, setEventList] = useState([]);
	const [prods, setProds] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);

	const getProductsbyKey = async (key) => {
		const response = await axiosPrivate.get(
			`client/products/${key.clientKey}`
		);
		return response;
	};

	const getEncodedData = async () => {
		const response = await axiosPrivate.get(
			`/label/commission/table/?currentPage=${currentPage}&startdate=${params.startdate}&enddate=${params.enddate}&labeltype=${params.labelType}&clientKeys=${params.clientKey}&prodIds=${params.prodIds}`
		);
		return response;
	};

	// const downloadEncodedData = async (data) => {
	// 	const response = await axiosPrivate.post(
	// 		"label/commission/download",
	// 		data
	// 	);
	// 	return response;
	// };

	const handleChange = async (key) => {
		try {
			const prodResponse = await getProductsbyKey({ clientKey: key });
			setProds([...prodResponse.data.data]);
		} catch (error) {
			console.log(error);
		}
	};

	const [params, setParams] = useState({
		startdate: "2023-01-01",
		enddate: format(new Date(), "yyyy-MM-dd"),
		labelType: "all",
		clientKey: "all",
		prodIds: "all",
	});

	// const header = [
	// 	{ key: "prodName", label: "product Name" },
	// 	{ key: "clientName", label: "Client Name" },
	// 	{ key: "labelType", label: "Label-Type" },
	// 	{ key: "count", label: "Volume" },
	// 	{ key: "createdAt", label: "Date" },
	// ];

	const { refetch, isFetching } = useQuery({
		queryKey: ["label-commissions", currentPage],
		queryFn: () => getEncodedData(),
		refetchOnMount: true,
		refetchOnWindowFocus: false,
		onSuccess: (data) => {
			pageCount = data?.data?.data?.pageCount;
			setEventList(data?.data?.data?.row);
		},
		onError: (error) => {
			console.log(error);
			if (
				error.response?.status === "403" ||
				error.response?.status === "401"
			) {
				navigate("/");
			}
			setEventList([]);
		},
	});

	// const download = useMutation(
	// 	(variables) => downloadEncodedData(variables),
	// 	{
	// 		onSuccess(data, variables, context) {
	// 			setDownloadData(
	// 				data?.data?.data.map((event) => {
	// 					return {
	// 						prodName: event.prodName,
	// 						clientName: event.clientName,
	// 						labelType:
	// 							event.labelType === "0" ? "SGTIN" : "SSCC",
	// 						count: event.count,
	// 						createdAt: format(
	// 							new Date(event.createdAt),
	// 							"yyyy-MM-dd hh:mm:ss"
	// 						),
	// 					};
	// 				})
	// 			);
	// 		},
	// 		onError(error) {},
	// 	}
	// );

	// function handleLabelDownload() {
	// 	download.mutate(params);
	// }

	function handleParams() {
		setCurrentPage(1);
		refetch();
	}

	const onPageChange = (page) => {
		setCurrentPage(page);
	};

	return (
		<div className=" overflow-x-hidden">
			<Card className=" overflow-x-hidden">
				<CardHeader>
					<CardTitle className="my-[10px]">
						Items Commissioned
					</CardTitle>
					<CardDescription>
						<div className=" w-[100%] flex gap-10 mb-5">
							<Select
								className=" w-[50%] flex-1"
								// isMulti
								onChange={(e) => {
									handleChange(e.value);
									setParams({
										...params,
										clientName: e.value,
									});
								}}
								// placeholder="Select user roles"
								options={[
									{ value: "all", label: "all" },
									...allClients?.map((client) => {
										return {
											value: client?.key,
											label: client?.name,
										};
									}),
								]}
							/>
							<Select
								className=" w-[50%] flex-1"
								// isMulti
								onChange={(e) => {
									setParams({ ...params, prodName: e.value });
								}}
								// placeholder="Select user roles"
								options={[
									...prods.map((prod, index) => {
										return {
											value: prod.id,
											label: prod.prod_name,
										};
									}),
								]}
							/>
						</div>
						<div className="w-[100%] flex align-middle gap-2 pt-3 pb-3">
							<Select
								className=" w-52"
								// isMulti
								onChange={(e) => {
									setParams({
										...params,
										labelType: e.value,
									});
								}}
								placeholder="Select Label type"
								options={[
									...labelType?.map((label) => {
										return {
											value: label.value,
											label: label.label,
										};
									}),
								]}
							/>
							<span>
								<label htmlFor="" className=" p-3">
									Start-Date:
								</label>
								<input
									type="date"
									name="start-date"
									id=""
									className="w-60 border-2 cursor-pointer h-[42px]"
									onChange={(e) =>
										setParams({
											...params,
											startate: e.target.value,
										})
									}
								/>
							</span>

							<span className="">
								<label htmlFor="" className=" p-3">
									End-Date:
								</label>

								<input
									type="date"
									name="end-date"
									id=""
									onChange={(e) =>
										setParams({
											...params,
											enddate: e.target.value,
										})
									}
									className="w-60 border-2 cursor-pointer h-[42px]"
								/>
							</span>
							<Button onClick={handleParams}>Search</Button>
							{/* <Button onClick={handleLabelDownload}>Download</Button> */}
						</div>
					</CardDescription>
				</CardHeader>
				<CardContent className="space-y-2">
					<Table>
						<TableCaption>
							A list of all label commissioned.
						</TableCaption>
						<TableHeader>
							<TableRow>
								<TableHead>Product Name</TableHead>
								<TableHead>Client Name</TableHead>
								<TableHead className=" whitespace-nowrap">
									Label-Type
								</TableHead>
								<TableHead>Volume</TableHead>
								<TableHead className=" text-end">
									Date
								</TableHead>
							</TableRow>
						</TableHeader>
						<TableBody>
							{isFetching && (
								<TableRow>
									<TableCell colSpan={5}>
										<div className="flex justify-center">
											<ClipLoader />
										</div>
									</TableCell>
								</TableRow>
							)}
							{eventList.map((event, index) => (
								<TableRow key={index}>
									<TableCell className=" whitespace-nowrap">
										{event?.clientName}
									</TableCell>
									<TableCell className=" whitespace-nowrap">
										{event?.prodName}
									</TableCell>
									<TableCell>{event?.labelType}</TableCell>
									<TableCell>{event?.count}</TableCell>
									<TableCell className=" text-end">
										{" "}
										{format(
											new Date(event.createdAt),
											"yyyy-MM-dd hh:mm:ss"
										)}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
						<TableFooter>
							<TableRow>
								<TableCell colSpan={5}>Total</TableCell>
							</TableRow>
							<TableRow>
								<TableCell colSpan={4}></TableCell>
								<TableCell colSpan={1}>
									{pageCount > 1 && (
										<Paginator
											currentPage={currentPage}
											disabled={isFetching}
											onPageChange={onPageChange}
											pageCount={pageCount}
										/>
									)}
								</TableCell>
							</TableRow>
						</TableFooter>
					</Table>
				</CardContent>
			</Card>
		</div>
	);
}

export default CommissionedTab;
