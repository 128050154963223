import { Button } from "../components/ui/button";
import { Card } from "../components/ui/card";
import React, { useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import img from "../pics/landImg.jpeg";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../components/ui/form";
import { Input } from "../components/ui/input";
import { useToast } from "../components/ui/use-toast";
import { useMutation } from "react-query";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { gs1_api } from "../utils/apis/api";
import { ClipLoader } from "react-spinners";
import { setAuth } from "../store/features/authSlice";
import { useDispatch } from "react-redux";

function Login() {
    const dispatch = useDispatch();
  const { toast } = useToast();
  const formSchema = z.object({
    email: z.string().email({
      message: "Only valid email is allowed",
    }),
    password: z.string().min(2, {
      message: "This field is required",
    }),
  });
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const { auth } = useAuth();
// const {auth} = useSelector();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/pages";

  const userLogin = async (data) => {
    console.log("login in.........");

    const response = await gs1_api.post("/user/login", data, {
      headers: {
        "content-type": "application/json",
      },
      withCredentials: true,
    });
    return response;
  };

  const { mutate, isLoading } = useMutation(
    (variables) => userLogin(variables),
    {
      onSuccess(data, variables, context) {
        dispatch(setAuth({
			accessToken: data.data.data.accessToken,
			user: data.data.data.user,
			providerToken: data.data.data.providerToken,
			login: true,
		}));
        sessionStorage.setItem("login", true);
        navigate(from, { replace: true });
      },
      onError(error) {
        if (error.code !== "ERR_NETWORK") {
          console.log(error.response);
          toast({
            variant: "destructive",
            title: "Error",
            description: `${error?.response?.data?.message}`,
          });
        } else {
          console.log(error);
          toast({
            variant: "destructive",
            title: "Error",
            description: "Please Check Your Network",
          });
        }
      },
    }
  );

  useEffect(() => {
    navigator.permissions.query({ name: "geolocation" }).then((resp) => {
      console.log("geting location ......................");
      if (resp.state === "granted" || resp.state === "prompt") {
        console.log("granted");
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(success, error);
        }
      } else if (resp.state === "denied") {
        toast({
          variant: "destructive",
          title: "Error",
          description: "Please application need to access your location",
        });
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(success, error);
        }
      }
    });

    function success(position) {}

    function error(err) {
      console.log(err);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Please application need to access your location",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 2. Define a submit handler.
  function onSubmit(values) {
    mutate(values);
  }
  if (auth?.login === true) {
    return <Navigate to="/pages" />;
  } else
    return (
      <div className="w-full h-full">
        <div className=" pl-24  pt-10">
          <img src="/sproxillogo.png" alt="" className="w-[120px]" />
        </div>
        <div className="flex flex-col">
          <div className="h-[80vh] flex flex-col gap-[10px] justify-center items-center">
            <Card className=" h-[fit-content] w-[350px] p-[20px] min-w-[230px]">
              <h1 className="font-extrabold text-xl">Welcome back! </h1>
              <h4 className=" text-xs">Great to have you. Happy Tracking</h4>
              <br />
              <Form {...form}>
                <form
                  onSubmit={form.handleSubmit(onSubmit)}
                  className="space-y-8">
                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Email</FormLabel>
                        <FormControl>
                          <Input placeholder="johndoe@gmail.com" {...field} />
                        </FormControl>
                        <FormDescription>
                          This is your registered email.
                        </FormDescription>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="password"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Password</FormLabel>
                        <FormControl>
                          <Input placeholder="" {...field} type="password" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <Button type="submit" variant="" disabled={isLoading}>
                    {isLoading ? (
                      <span className="mx-[20px]">
                        <ClipLoader color="primary" size={20} />
                      </span>
                    ) : (
                      <span> SignIn</span>
                    )}
                  </Button>
                </form>
              </Form>
            </Card>
            <div className=" text-primary text-[9px]">
              Copyright &copy; {new Date().getFullYear()}
            </div>
          </div>
          <div className="h-[5vh] flex items-end w-full justify-center">
            <img src={img} alt="" className=" md:w-[65%] " />
          </div>
        </div>
      </div>
    );
}

export default Login;
