import { Route, Routes } from "react-router-dom";
import { Toaster } from "./components/ui/toaster";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Dashboard from "./pages/dashboard/Dashboard";
import PersistLogin from "./hooks/PersistLogin";
import Client from "./pages/Client";
import User from "./pages/User";
import Serialization from "./pages/serialization/Serialization";
import { SidebarProvider } from "./components/ui/sidebar";

function App() {
  return (
		<SidebarProvider>
			<Toaster />
			<Routes>
				<Route index element={<Login />} />
				<Route element={<PersistLogin />}>
					<Route path="pages" element={<Home />}>
						<Route index element={<Dashboard />} />
						<Route path="/pages/client" element={<Client />} />
						<Route path="/pages/user" element={<User />} />
						<Route
							path="/pages/serialization"
							element={<Serialization />}
						/>
					</Route>
				</Route>
			</Routes>
		</SidebarProvider>
  );
}

export default App;
