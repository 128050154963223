import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	allClients: [],
};

export const clientSlice = createSlice({
	name: "clients",
	initialState,
	reducers: {
		setClients: (state, { payload }) => {
			return { ...state, allClients: payload };
		},
		resetClients: (state) => {
			return { ...state, allClients: [] };
		},
	},
});

export const { setClients, resetClients } = clientSlice.actions;

export default clientSlice.reducer;
